// Router
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// Global styles
import "./index.css"

// Pages
import App from "./pages/App"
import Contact from "./pages/Contact/Contact"
import { AboutUs } from "./pages/AboutUs/AboutUs"
import Locator from "./pages/Locator/Locator";
import WhatsappChat from "./components/WhatsappChat/WhatsappChat";
import Products from "./pages/Products/Products";
import RatingsIcon from "./components/RatingsIcon/RatingsIcon";

function RouterComponent() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/products" element={<Products />} />
        <Route path="/store-locator" element={<Locator />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <div className="flex flex-col items-center justify-center">
      <WhatsappChat />
      <RatingsIcon />
      </div>
    </Router>
  );
}

export default RouterComponent;
