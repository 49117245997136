import React from 'react'

// components
import { ProductSection } from '../../components/ProductSection/ProductSection'
import Navbar from '../../components/Navbar/Navbar'
import { Footer } from '../../components/Footer/Footer'

export default function Products() {
  return (
    <div className='w-full'>
      <Navbar />
      <ProductSection />
      <Footer />
    </div>
  )
}
