import React, { useState, useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import useMediaQuery from '../../components/useMediaQuery';

// icons
import { IoClose, IoReorderThree } from 'react-icons/io5';
import { SiDeliveroo } from "react-icons/si";
import { FaShopify } from "react-icons/fa";

const Navbar = () => {
    const [isMenuToggled, setIsMenuToggled] = useState(false);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [isHoveringShopify, setIsHoveringShopify] = useState(false); 
    const [isHoveringAmazon, setIsHoveringAmazon] = useState(false); 
    const [isHoveringNoon, setIsHoveringNoon] = useState(false); 
    const [isHoveringTalabat, setIsHoveringTalabat] = useState(false); 
    const [isHoveringInstaShop, setIsHoveringInstaShop] = useState(false); 
    const dropdownRef = useRef(null);
    const buttonRef = useRef(null);
    const isDesktop = useMediaQuery("(min-width: 768px)");
    const location = useLocation();

    // Handle clicks outside of the dropdown and button
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target) &&
                buttonRef.current &&
                !buttonRef.current.contains(event.target)
            ) {
                setIsDropdownVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleDropdown = () => {
        setIsDropdownVisible(prev => !prev);
    };

    return (
        <nav className="z-40 w-full h-20 flex items-center bg-[#eee] top-0 py-6">
            <div className="flex items-center justify-between w-full px-4">
                <Link to="/">
                    <img className='h-[140px] w-[140px]' alt="logo" src="../assets/flextech.png" />
                </Link>

                {/* DESKTOP NAV */}
                {isDesktop ? (
                    <div className="flex justify-between gap-10 font-opensans lg:text-lg text-sm font-medium">
                        <Link to="/" className={`hover:underline ${
                            location.pathname === "/" ? "underline" : ""
                        }`}>
                            Home
                        </Link>
                        <div className="relative">
                            <button 
                                ref={buttonRef}
                                onClick={toggleDropdown} 
                                className="hover:underline"
                            >
                                Shop Now
                            </button>
                            {isDropdownVisible && (
                                <div
                                    ref={dropdownRef}
                                    className="absolute top-full mt-2 bg-white shadow-lg border border-gray-200 rounded-md z-50"
                                >
                                    <span className='border-b border-gray-300 flex items-center justify-between gap-2 hover:bg-green-400 px-4 py-2'>
                                    <a
                                        href="https://deliveroo.ae/menu/dubai/al-barsha-park-1/flextech-mobile-phone-and-accessories-barsha" 
                                        target='_blank' 
                                        rel='noreferrer'
                                    >
                                        Deliveroo
                                    </a>
                                    <SiDeliveroo className='text-[#00CCBC]' />
                                    </span>
                                    <span className='flex items-center justify-between gap-2 border-b border-gray-300 hover:bg-green-400 px-4 py-2'>
                                    <a
                                        href="https://wa.me/c/971542664712" 
                                        target='_blank' 
                                        rel='noreferrer'
                                        className="flex items-center"
                                    >
                                        WA Store
                                    </a>
                                        <img src="/assets/store.png" className='w-[18px] h-[18px]' alt="" />
                                    </span>
                                    <span className='flex items-center justify-between gap-2 border-b border-gray-300 hover:bg-green-400 px-4 py-2'>
                                    <a
                                        href="https://www.amazon.ae/s?me=AD56ZDTOIHN44&marketplaceID=A2VIGQ35RCS4UG" 
                                        target='_blank' 
                                        rel='noreferrer'
                                        className="flex items-center"
                                    >
                                        Amazon
                                    </a>
                                        <img src="/assets/amazon.png" className='w-[20px] h-[20px]' alt="" />
                                    </span>
                                    <span
                                        className="relative flex items-center justify-between gap-2 border-b border-gray-300 cursor-pointer hover:bg-red-500 px-4 py-2"
                                        onMouseEnter={() => setIsHoveringShopify(true)}
                                        onMouseLeave={() => setIsHoveringShopify(false)}
                                    >
                                    {isHoveringShopify ? (
                                        <span className=" lg:text-lg text-sm font-medium cursor-pointer">Soon..</span>
                                    ) : (
                                        <>
                                        <span>
                                            Shopify
                                        </span>
                                            <FaShopify className='text-[#96bf48]' />
                                        </>
                                    )}
                                    </span>
                                    <span
                                        className="relative flex items-center justify-between gap-2 cursor-pointer border-b border-gray-300  hover:bg-red-500 px-4 py-2"
                                        onMouseEnter={() => setIsHoveringNoon(true)}
                                        onMouseLeave={() => setIsHoveringNoon(false)}
                                    >
                                    {isHoveringNoon ? (
                                        <span className=" lg:text-lg text-sm font-medium cursor-pointer">Soon..</span>
                                    ) : (
                                        <>
                                            Noon
                                            <img src="/assets/noon.png" className='w-[18px] h-[18px]' alt="" />
                                        </>
                                    )}
                                    </span>
                                    <span
                                        className="relative flex items-center justify-between gap-2 cursor-pointer border-b border-gray-300  hover:bg-red-500 px-4 py-2"
                                        onMouseEnter={() => setIsHoveringTalabat(true)}
                                        onMouseLeave={() => setIsHoveringTalabat(false)}
                                    >
                                    {isHoveringTalabat ? (
                                        <span className=" lg:text-lg text-sm font-medium cursor-pointer">Soon..</span>
                                    ) : (
                                        <>
                                            Talabat
                                            <img src="/assets/talabat.png" className='w-[20px] h-[20px]' alt="" />
                                        </>
                                    )}
                                    </span>
                                    <span
                                        className="relative flex items-center justify-between cursor-pointer hover:bg-red-500 px-4 py-2"
                                        onMouseEnter={() => setIsHoveringInstaShop(true)}
                                        onMouseLeave={() => setIsHoveringInstaShop(false)}
                                    >
                                    {isHoveringInstaShop ? (
                                        <span className=" lg:text-lg text-sm font-medium cursor-pointer">Soon..</span>
                                    ) : (
                                        <>
                                            InstaShop
                                            <img src="/assets/insta-shop.png" className='w-[38px] h-[24px]' alt="" />
                                        </>
                                    )}
                                    </span>
                                </div>
                            )}
                        </div>
                        <Link to="/products" className={`hover:underline ${
                            location.pathname === "/products" ? "underline" : ""
                        }`}>
                            Products & Services
                        </Link>
                        <Link to="/store-locator" className={`hover:underline ${
                            location.pathname === "/store-locator" ? "underline" : ""
                        }`}>
                            Store 
                        </Link>
                        <Link to="/about" className={`hover:underline ${
                            location.pathname === "/about" ? "underline" : ""
                        }`}>
                            About us
                        </Link>
                        <Link to="/contact" className={`hover:underline ${
                            location.pathname === "/contact" ? "underline" : ""
                        }`}>
                            Contact us
                        </Link>
                    </div>
                ) : (
                    <button
                        onClick={() => setIsMenuToggled(!isMenuToggled)}
                    >
                        <IoReorderThree className='w-8 h-8' />
                    </button>
                )}

                {/* MOBILE MENU POPUP */}
                {!isDesktop && isMenuToggled && (
                    <div className="fixed z-50 right-0 bottom-0 h-full bg-white w-[300px]">
                        {/* CLOSE ICON */}
                        <div className="flex justify-end p-12">
                            <button onClick={() => setIsMenuToggled(!isMenuToggled)}>
                                <IoClose className='w-8 h-8' />
                            </button>
                        </div>

                        {/* MENU ITEMS */}
                        <div className="flex flex-col gap-10 ml-[33%] text-2xl text-deep-blue">
                            <Link to="/" className={`hover:underline ${
                                location.pathname === "/" ? "underline" : ""
                            }`}>
                                Home
                            </Link>
                            <div className="relative">
                                <button
                                    onClick={() => setIsDropdownVisible(prev => !prev)}
                                    className="hover:underline"
                                >
                                    Shop Now
                                </button>
                                {isDropdownVisible && (
                                    <div className="absolute top-full mt-2 bg-white shadow-lg border border-gray-200 rounded-md z-50">
                                        <span className='flex items-center justify-between border-b border-gray-300 hover:bg-green-400 mx-2 px-4 py-2'>
                                        <a
                                          href="https://deliveroo.ae/menu/dubai/al-barsha-park-1/flextech-mobile-phone-and-accessories-barsha" 
                                          target='_blank' 
                                          rel='noreferrer'
                                        >
                                             Deliveroo
                                        </a>
                                             <SiDeliveroo className='text-[#00CCBC]' />
                                        </span>
                                        <span className='flex items-center justify-between hover:bg-green-400 border-b border-gray-300 mx-2 px-4 py-2'>
                                            <a
                                                href="https://wa.me/c/971542664712" 
                                                target='_blank' 
                                                rel='noreferrer'
                                                className="flex items-center gap-7"
                                            >
                                                WA Store
                                                <img src="/assets/store.png" className='w-[22px] h-[22px]' alt="" />
                                            </a>
                                        </span>
                                        <a
                                        className="relative flex items-center justify-between cursor-pointer border-b border-gray-300  hover:bg-green-400 mx-2 px-4 py-2"
                                        href="https://www.amazon.ae/s?me=AD56ZDTOIHN44&marketplaceID=A2VIGQ35RCS4UG" 
                                        target='_blank' 
                                        rel='noreferrer'
                                    >
                                            Amazon
                                            <img src="/assets/amazon.png" className='w-[28px] h-[28px]' alt="" />
                                    
                                    </a>
                                    <span
                                        className="relative flex justify-between items-center cursor-pointer border-b border-gray-300 hover:bg-red-500 mx-2 px-4 py-2"
                                        onMouseEnter={() => setIsHoveringShopify(true)}
                                        onMouseLeave={() => setIsHoveringShopify(false)}
                                    >
                                    {isHoveringShopify ? (
                                        <span className="lg:text-lg text-sm font-medium cursor-pointer">Soon..</span>
                                    ) : (
                                        <>
                                            Shopify
                                            <FaShopify className='text-[#96bf48]' />
                                        </>
                                    )}
                                    </span>
                                    {/* <span
                                        className="relative flex items-center justify-between cursor-pointer border-b border-gray-300  hover:bg-red-500 mx-2 px-4 py-2"
                                        onMouseEnter={() => setIsHoveringAmazon(true)}
                                        onMouseLeave={() => setIsHoveringAmazon(false)}
                                    >
                                    {isHoveringAmazon ? (
                                        <span className="lg:text-lg text-sm font-medium cursor-pointer">Soon..</span>
                                    ) : (
                                        <>
                                            Amazon
                                            <img src="/assets/amazon.png" className='w-[28px] h-[28px]' alt="" />
                                        </>
                                    )}
                                    </span> */}
                                    <span
                                        className="relative flex justify-between items-center cursor-pointer border-b border-gray-300 hover:bg-red-500 mx-2 px-4 py-2"
                                        onMouseEnter={() => setIsHoveringNoon(true)}
                                        onMouseLeave={() => setIsHoveringNoon(false)}
                                    >
                                    {isHoveringNoon ? (
                                        <span className="lg:text-lg text-sm font-medium cursor-pointer">Soon..</span>
                                    ) : (
                                        <>
                                            Noon
                                            <img src="/assets/noon.png" className='w-[22px] h-[22px]' alt="" />
                                        </>
                                    )}
                                    </span>
                                    <span
                                        className="relative flex items-center justify-between cursor-pointer border-b border-gray-300  gap-12 hover:bg-red-500 mx-2 px-4 py-2"
                                        onMouseEnter={() => setIsHoveringTalabat(true)}
                                        onMouseLeave={() => setIsHoveringTalabat(false)}
                                    >
                                    {isHoveringTalabat ? (
                                        <span className="lg:text-lg text-sm font-medium cursor-pointer">Soon..</span>
                                    ) : (
                                        <>
                                            Talabat
                                            <img src="/assets/talabat.png" className='w-[22px] h-[22px]' alt="" />
                                        </>
                                    )}
                                    </span>
                                    <span
                                        className="relative flex items-center cursor-pointer justify-between gap-3 hover:bg-red-500 mx-2 px-4 py-2"
                                        onMouseEnter={() => setIsHoveringInstaShop(true)}
                                        onMouseLeave={() => setIsHoveringInstaShop(false)}
                                    >
                                    {isHoveringInstaShop ? (
                                        <span className="lg:text-lg text-sm font-medium cursor-pointer">Soon..</span>
                                    ) : (
                                        <>
                                            InstaShop
                                            <img src="/assets/insta-shop.png" className='w-[44px] h-[24px]' alt="" />
                                        </>
                                    )}
                                    </span>
                                    </div>
                                )}
                            </div>
                            <Link to="/products" className={`hover:underline ${
                                location.pathname === "/products" ? "underline" : ""
                            }`}>
                                Products & Services
                            </Link>
                            <Link to="/store-locator" className={`hover:underline ${
                                location.pathname === "/store-locator" ? "underline" : ""
                            }`}>
                                Store 
                            </Link>
                            <Link to="/about" className={`hover:underline ${
                                location.pathname === "/about" ? "underline" : ""
                            }`}>
                                About us
                            </Link>
                            <Link to="/contact" className={`hover:underline ${
                                location.pathname === "/contact" ? "underline" : ""
                            }`}>
                                Contact us
                            </Link>
                        </div>
                    </div>
                )}
            </div>
        </nav>
    );
};

export default Navbar;