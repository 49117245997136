import React from 'react'

// Components
import { SectionHeader } from '../SectionHeader/SectionHeader'
import { useLocation } from 'react-router-dom';

export default function LocatorSection() {
  const location = useLocation();

  const isLocatorPage = location.pathname === '/store-locator';
  const isHomePage = location.pathname === '/';
  return (
    <div>
      {isLocatorPage && (
        <div id="page-header3" style={{
          backgroundImage: 'url(/assets/about-banner.png)',
          backgroundSize: 'cover',
          backgroundPosition: 'center', 
          backgroundRepeat: 'no-repeat',
          width: '100%',
          // height: '20vh'
        }} className="about-header h-[20vh] md:h-[40vh]">
          <h2 className="text-[32px] md:text-[46px] font-bold">#FindUs</h2>
          <p className="text-[16px] font-medium mt-2">Discover Our Store Locations.</p>
        </div>
      )}
      {/* <SectionHeader caption="Timing and Location" /> */}

      <div className='lg:py-8 lg:mx-8 mt-8'>
      <div className="flex flex-col lg:flex-row justify-between items-center gap-y-10 lg:gap-x-2">
        <div className='flex-2 lg:h-[450px] lg:w-[500px] w-[300px] h-[100%] bg-gray-100 rounded-md shadow-md'>
            <div className='border-b-2 border-gray-200 mt-2'>
                <div className='mb-2'>
                  <h2 className='text-black font-medium text-base lg:text-lg font-opensans ml-3'>FlexTech (Branch 1)</h2>
                </div>
            </div>
            <div className='flex flex-col gap-6 ml-3 mt-4 text-base text-gray-500 font-normal font-opensans lg:text-lg'>
                <span>Kiosk No 27, Main Entrance, Ground Floor, AB Center AI Barsha 1, Dubai</span>
                <span>Store Timings: <br /> Mon to Sat: 8:00 AM - 10:30 PM <br /> Sun: 12:00 PM - 10:00 PM</span>
                <span>Contact: +97154 266 4712</span>
            </div>
        </div>

        <div>
        <iframe className='rounded-md lg:w-[500px] w-[100%] lg:h-[450px] h-[100%] flex-1' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3612.8004939642224!2d55.18146347402146!3d25.108613935285565!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6ba83f703d31%3A0x98b161f539221317!2sFlexTech%20Mobile%20Phones%20%26%20Accessories!5e0!3m2!1sen!2s!4v1723917741119!5m2!1sen!2s" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>

        <div>
          <img className='lg:h-[450px] lg:w-[500px] rounded-md shadow-md flex-1 h-[100%] w-[300px] mx-auto' src="/assets/img-6.jpg" alt="" />
        </div>
      </div>
      </div>
    </div>
  )
}
