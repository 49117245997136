import React from 'react'

export default function RatingsIcon() {
  return (
    <div className='z-50'>
      <a href="https://g.page/r/CRcTIjn1YbGYEAE/review" target='_blank' rel='noreferrer'> 
        <img src="/assets/ratings-icon.png" alt="ratings-icon" className='fixed lg:bottom-8 bottom-[2%] right-[14px] left-50 w-[46px] h-[46px]' />
      </a>
    </div>
  )
}
