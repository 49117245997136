import { useState } from "react"
import { useLocation } from 'react-router-dom';

// Components
import { SectionHeader } from "../SectionHeader/SectionHeader"

export const ContactSection = () => {

  const location = useLocation();

  const isContactUsPage = location.pathname === '/contact';
  const isHomePage = location.pathname === '/';

  const [data, setData] = useState({
    name: "",
    email: "",
    message: "",
  })

  const handleSubmit = (event) => {
    event.preventDefault();

    const mailtoLink = `mailto:info@flextech-telecom.com?subject=Contact%20Form%20Submission&body=Name:%20${encodeURIComponent(data.name)}%0D%0AEmail:%20${encodeURIComponent(data.email)}%0D%0APhone:%20${encodeURIComponent(data.phone)}%0D%0AMessage:%20${encodeURIComponent(data.message)}`;

    window.location.href = mailtoLink;

    setData({ name: "", email: "", phone: "", message: "" });
  };

  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value })
  }

  return (
    <div>
   
    <div className="isolate gap-y-8 mt-8 h-full">
      <SectionHeader caption="Contact Us" />

      <form
        onSubmit={handleSubmit}
        className="mx-auto max-w-sm md:max-w-lg lg:max-w-2xl text-center"
      >
        <div className="grid grid-cols-1 gap-x-8 gap-y-1 sm:grid-cols-2">
          <div className="sm:col-span-2">
            <div className="mt-[2px]">
              <input
                type="text"
                name="name"
                id="name"
                required
                value={data.name}
                autoComplete="name"
                onChange={handleChange}
                placeholder="Name"
                className="w-full bg-white shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-deep-blue font-semibold placeholder-opaque-black p-3 mt-5 outline-none"
              />
            </div>
          </div>

          <div className="sm:col-span-2">
            <div className="mt-[2px]">
              <input
                type="email"
                name="email"
                required
                id="email"
                autoComplete="email"
                value={data.email}
                onChange={handleChange}
                placeholder="Email"
                className="w-full bg-white shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-deep-blue font-semibold placeholder-opaque-black p-3 mt-5 outline-none"
              />
            </div>
          </div>

          <div className="sm:col-span-2">
            <div className="mt-[2px]">
              <input
                type="number"
                name="phone"
                required
                id="phone"
                autoComplete="phone"
                value={data.phone}
                onChange={handleChange}
                placeholder="Phone"
                className="w-full bg-white shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-deep-blue font-semibold placeholder-opaque-black p-3 mt-5 outline-none"
              />
            </div>
          </div>


          <div className="sm:col-span-2">
            <div className="mt-[2px]">
              <textarea
                name="message"
                required
                id="message"
                rows={4}
                onChange={handleChange}
                value={data.message}
                placeholder="Message"
                className="w-full bg-white shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-deep-blue font-semibold placeholder-opaque-black p-3 mt-5 outline-none"
                defaultValue={""}
              />
            </div>
          </div>
        </div>
        <div className="mt-4">
            <button
              className="p-3 flex flex-start bg-green-500 font-semibold text-deep-blue hover:bg-green-700 hover:text-white transition duration-500"
              type="submit"
            >
              Send Message
            </button>
        </div>
      </form>
    </div>
    </div>
  )
}
