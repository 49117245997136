import React from "react"
import { useLocation } from 'react-router-dom';

// Components
import { SectionHeader } from "../SectionHeader/SectionHeader"

export const AboutSection = () => {
  const location = useLocation();

  const isAboutUsPage = location.pathname === '/about';
  const isHomePage = location.pathname === '/';
  return (
    <>
     {isAboutUsPage && (
        <div id="page-header3" style={{
          backgroundImage: 'url(/assets/about-banner.png)',
          backgroundSize: 'cover',
          backgroundPosition: 'center', 
          backgroundRepeat: 'no-repeat',
          width: '100%',
          // height: '40vh'
        }} className="about-header h-[20vh] md:h-[40vh]">
          <h2 className="text-[32px] md:text-[46px] font-bold">#KnowUs</h2>
          <p className="md:text-[16px] font-medium mt-2">Get to know us and discover the difference.</p>
        </div>
      )}

      {/* {isHomePage && (
      <div className="mt-10 font-bold">
         <marquee style={{ 
        lineHeight: "50px",
        fontFamily: "Acme",
        fontSize: "22px",
        fontWeight: "100",
        margin: 0,
        padding: 0,
      }}  bgcolor="#eee" loop="-1" scrollamount="6" height="50px" width="100%">
        Exciting News! Tabby payment service is now available at our AB Center branch in Al Barsha 1, allowing you to shop conveniently in 4 interest-free installments. Visit us today!
      </marquee>
      </div>
      )} */}

    <div className="p-[40px] max-w-[1800px] mx-auto md:gap-y-8 gap-y-0 flex flex-col">
      {isHomePage && (
         <SectionHeader title="About us" caption="Get to know us" />
      )}

      {/* Content With Image */}
      <div className="flex flex-col lg:flex-row justify-center items-center md:gap-y-10 gap-y-6 lg:gap-x-10">
        <div className="flex-1 w-full flex justify-center items-center">
          <img
            src="/assets/img-10.jpg"
            alt="accessories"
            className="rounded-lg lg:w-6/7"
          />
        </div>

        <div className="flex-1 w-full flex flex-col justify-center items-center lg:items-start">
          <p className="font-normal text-gray-800 max-w-xl text-base leading-[140%] lg:leading-[150%] lg:text-lg">
          We at FlexTech specialize in selling mobiles and accessories, with a focus on providing flexibility to our customers. Our mission is to ensure that people are free to switch and upgrade their mobile devices effortlessly, without being tied down to a single gadget. Experience the convenience of choosing a new phone or accessory whenever you feel like it. We specialize in providing high quality accessories to enhance your tech experience. From durable phone cases and high-speed chargers to stylish earbuds and screen protectors, our diverse range of products ensures your mobile devices are always protected and performing at their best. Committed to exceptional customer service and competitive pricing, Flextech is your go-to destination for all your mobile.
          </p>
        </div>
      </div>
    </div>
    </>
  )
}
