import React from 'react'

// Components
import LocatorSection from '../../components/LocatorSection/LocatorSection'
import { Footer } from '../../components/Footer/Footer'
import Navbar from '../../components/Navbar/Navbar'


export default function Locator() {
  return (
    <div className='width-full'>
      <Navbar />
      <LocatorSection />
      <Footer />
    </div>
  )
}
