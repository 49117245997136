import React from 'react'

// components
import { ContactSection } from '../../components/ContactSection/ContactSection'
import { Footer } from '../../components/Footer/Footer';
import Navbar from '../../components/Navbar/Navbar';
import { useLocation } from 'react-router-dom';


function Contact() {
  const location = useLocation();

  const isContactUsPage = location.pathname === '/contact';
  const isHomePage = location.pathname === '/';

  return (
    <div className='w-full'>
      <Navbar />
      {isContactUsPage && (
        <div id="page-header3" style={{
          backgroundImage: 'url(/assets/contact-banner.jpg)',
          backgroundSize: 'cover',
          backgroundPosition: 'center', 
          backgroundRepeat: 'no-repeat',
          width: '100%',
          // height: '40vh'
        }} className="about-header p-0 m-0 h-[20vh] md:h-[40vh]">
          <h2 className="text-[32px] md:text-[46px] font-bold">#Let's_Talk</h2>
          <p className="md:text-[16px] font-medium mt-2">We love to hear from you!</p>
        </div>
      )}
      <div className='p-[40px] max-w-[1800px] mx-auto gap-10'>
        <ContactSection />
        <div className="mx-auto max-w-sm md:max-w-lg lg:max-w-2xl mt-[70px] flex flex-col lg:flex-row lg:gap-x-4 gap-y-4">
        <div className="drop-shadow-xl text-sm text-center gap-y-2 flex flex-col border border-primary border-opacity-30 shadow-md w-full lg:flex-1 p-[20px] rounded-lg">
          <div className="font-medium text-lg">Phone</div>
          <div>+971-54 266 4712</div>
        </div>
        <div className="drop-shadow-xl text-sm text-center gap-y-2 flex flex-col border border-primary border-opacity-30 shadow-md w-full lg:flex-1 p-[20px] rounded-lg">
          <div className="font-medium text-lg">Email</div>
          <div>info@flextech-telecom.com</div>
        </div>
      </div>
      </div>
      <Footer />
    </div>
  )
}

export default Contact;
