import React from "react"

export const SectionHeader = ({ title, caption }) => {
  return (
    <div className="flex flex-col text-center pb-[50px] pt-[40px]">
      <p className="text-2xl text-primary font-bold font-opensans text-deep-blue">{title}</p>
      <h1 className="text-4xl lg:text-5xl font-opensans font-medium text-deep-blue">
        {caption}
      </h1>
    </div>
  )
}
