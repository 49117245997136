import React from "react"

// Components
import { ProductCard } from "../ProductCard/ProductCard"


// Data
import { SectionHeader } from "../SectionHeader/SectionHeader"
import { products } from "../../data"
import { useLocation } from "react-router-dom"
import useMediaQuery from "../useMediaQuery"

export const ProductSection = () => {
  const location = useLocation();
  const isDesktop = useMediaQuery("(min-width: 1024px)");

  const isProductsPage = location.pathname === '/products';
  const isHomePage = location.pathname === '/';
  return (
    <>
     {isProductsPage && (
        <div id="page-header3" style={{
          backgroundImage: 'url(/assets/img-3.jpg)',
          backgroundSize: 'cover',
          backgroundPosition: 'center', 
          backgroundRepeat: 'no-repeat',
          width: '100%',
          // height: '40vh'
        }} className="about-header h-[20vh] md:h-[40vh]">
          <h2 className="text-[32px] md:text-[46px] font-bold">#Our_Collection</h2>
          <p className="md:text-[16px] font-medium mt-2">Explore a wide range of top-quality items.</p>
        </div>
      )}

      {/* {isHomePage && (
      <div className="md:mt-8 mt-0 font-bold">
         <marquee style={{ 
        lineHeight: "50px",
        fontFamily: "Acme",
        fontSize: "22px",
        fontWeight: "100",
        margin: 0,
        padding: 0,
      }}  bgcolor="#eee" loop="-1" scrollamount="6" height="50px" width="100%">
        Exciting News! Tabby payment service is now available at our AB Center branch in Al Barsha 1, allowing you to shop conveniently in 4 interest-free installments. Visit us today!
      </marquee>
      </div>
      )} */}
    
    <div className="gap-y-2 flex flex-col">
    {/* {isHomePage && (  
      <SectionHeader caption="Products & Services" />
    )} */}

      <div className="flex flex-wrap md:flex-row md:gap-x-8 gap-y-6 items-center md:items-start justify-center px-[40px] mt-[24px] flex-col text-white">
        {products &&
          products?.map(({ id, title, description, iconSrc, imageURL }) => (
            <ProductCard
              key={id}
              title={title}
              description={description}
              iconSrc={iconSrc}
              imageURL={imageURL}
            />
          ))}
      </div>
    </div>
  </>
  )
}
