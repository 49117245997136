import React, { useEffect, useState } from "react";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { SiDeliveroo } from "react-icons/si";
import { sliderData } from "./sliderData";
import useMediaQuery from "../useMediaQuery";

const shuffleArray = (array) => {
  let shuffledArray = array.slice();
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
};

const Slider = () => {
  const isDesktop = useMediaQuery("(min-width: 1024px)");
  const [currentSlide, setCurrentSlide] = useState(0);
  const [shuffledSlides, setShuffledSlides] = useState([]);

  const slideLength = shuffledSlides.length;
  const autoScroll = true;
  let slideInterval;
  const intervalTime = 2000;

  const nextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === slideLength - 1 ? 0 : prevSlide + 1
    );
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? slideLength - 1 : prevSlide - 1
    );
  };

  useEffect(() => {
    setShuffledSlides(shuffleArray(sliderData));
    setCurrentSlide(0);
  }, []);

  useEffect(() => {
    if (autoScroll) {
      slideInterval = setInterval(nextSlide, intervalTime);
      return () => clearInterval(slideInterval); // Clear the interval on cleanup
    }
  }, [autoScroll, shuffledSlides]); // Add shuffledSlides to dependencies

  return (
    <>
      {isDesktop ? (
      <>
        <div className="flex flex-row h-[50%]">
          <div className="flex flex-1">
            <div className="relative md:mx-auto mx-4 my-8 md:rounded-lg md:w-[600px] md:h-full h-[38%] overflow-hidden">
              {/* Slides */}
              {shuffledSlides.map((slide, index) => {
                const { image } = slide;
                return (
                  <div
                    key={index}
                    className={`absolute top-0 left-0 w-full h-full opacity-0 transition-opacity duration-1000 ease-in-out md:rounded-lg px-2 lg:py-0 py-10 ${
                      index === currentSlide
                        ? "opacity-100 translate-x-0"
                        : "translate-x-1/2"
                    }`}
                  >
                    {index === currentSlide && (
                      <div className="relative flex items-center justify-center w-full h-full overflow-hidden md:rounded-lg">
                        <img
                          style={{ borderRadius: "6px" }}
                          src={image}
                          alt="slide"
                          className="lg:h-full lg:w-full h-full w-full rounded-lg mx-auto overflow-hidden"
                        />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            {/* Navigation Icons */}
            <div className="absolute md:top-[40%] top-[34%] left-0 right-1/3 flex justify-between px-10 transform -translate-y-1/2 z-30">
              <MdKeyboardArrowLeft
                className="arrow prev md:w-[40px] w-[20px] h-auto cursor-pointer"
                onClick={prevSlide}
              />
              <MdKeyboardArrowRight
                className="arrow next md:w-[40px] w-[20px] h-auto cursor-pointer"
                onClick={nextSlide}
              />
            </div>
          </div>

          <div className="md:flex md:flex-[0.5] md:flex-col md:justify-center items-center mx-auto">
            <img
              src="/assets/shop-now.png"
              className="max-w-[340px] h-auto my-0"
              alt=""
            />
            <div className="flex gap-4 mx-auto mt-[8px]">
              <a
                href="https://deliveroo.ae/menu/dubai/al-barsha-park-1/flextech-mobile-phone-and-accessories-barsha"
                target="_blank"
                rel="noreferrer"
                className="button"
              >
                Deliveroo
                <SiDeliveroo className="text-[#00CCBC]" />
              </a>

              <a
                href="https://www.amazon.ae/s?me=AD56ZDTOIHN44&marketplaceID=A2VIGQ35RCS4UG"
                target="_blank"
                rel="noreferrer"
                className="button flex justify-center items-center"
              >
                Amazon
                <img src="/assets/amazon.png" className="w-[19px] h-[19px]" alt="" />
              </a>
            </div>
          </div>
        </div>

        <div className="md:mt-8 mt-0 font-bold">
         <marquee style={{ 
        lineHeight: "50px",
        fontFamily: "Acme",
        fontSize: "22px",
        fontWeight: "100",
        margin: 0,
        padding: 0,
      }}  bgcolor="#eee" loop="-1" scrollamount="6" height="50px" width="100%">
        Exciting News! iPhone 16 Pro Max is now Available on Tabby.
      </marquee>
      </div>
      </>
      ) : (
        <>
        <div className="flex relative mx-4 my-0 rounded-lg h-[38%] overflow-hidden">
          {/* Slides */}
          {shuffledSlides.map((slide, index) => {
            const { image } = slide;
            return (
              <div
                key={index}
                className={`absolute top-0 left-0 w-full h-full opacity-0 transition-opacity duration-1000 ease-in-out md:rounded-lg px-2 lg:py-0 py-4 ${
                  index === currentSlide
                    ? "opacity-100 translate-x-0"
                    : "translate-x-1/2"
                }`}
              >
                {index === currentSlide && (
                  <div className="relative flex items-center justify-center w-full h-full overflow-hidden md:rounded-lg">
                    <img
                      style={{ borderRadius: "6px" }}
                      src={image}
                      alt="slide"
                      className="lg:h-full lg:w-full h-full w-full rounded-lg mx-auto overflow-hidden"
                    />
                  </div>
                )}
              </div>
            );
          })}

          {/* Navigation Icons */}
          <div className="absolute md:top-[40%] top-[44%] left-0 right-0 flex justify-between transform -translate-y-1/2 z-30">
            <MdKeyboardArrowLeft
              className="arrow prev md:w-[40px] w-[30px] h-[auto] cursor-pointer"
              onClick={prevSlide}
            />
            <MdKeyboardArrowRight
              className="arrow next md:w-[40px] w-[30px] h-[auto] cursor-pointer"
              onClick={nextSlide}
            />
          </div>
        </div>

        <div className="font-bold mb-2">
         <marquee style={{ 
        lineHeight: "40px",
        fontFamily: "Acme",
        fontSize: "16px",
        fontWeight: "100",
        margin: 0,
        padding: 0,
        }}  bgcolor="#eee" loop="-1" scrollamount="6" height="40px" width="100%">
        Exciting News! iPhone 16 Pro Max is now Available on Tabby.
        </marquee>
        </div>
        <div className="flex flex-col justify-center items-center mt-0 mx-auto mb-4">
            <img
              src="/assets/shop-now.png"
              className="max-w-[340px] h-auto my-0"
              alt=""
            />
            <div className="flex gap-4 mx-auto mt-[8px]">
              <a
                href="https://deliveroo.ae/menu/dubai/al-barsha-park-1/flextech-mobile-phone-and-accessories-barsha"
                target="_blank"
                rel="noreferrer"
                className="button"
              >
                Deliveroo
                <SiDeliveroo className="text-[#00CCBC]" />
              </a>

              <a
                href="https://www.amazon.ae/s?me=AD56ZDTOIHN44&marketplaceID=A2VIGQ35RCS4UG"
                target="_blank"
                rel="noreferrer"
                className="button"
              >
                Amazon
                <img src="/assets/amazon.png" className="w-[19px] h-[19px]" alt="" />
              </a>
            </div>
          </div>
        
      </>
      )}
    </>
  );
};

export default Slider;
