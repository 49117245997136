import React from 'react'

export default function WhatsappChat() {
  return (
    <div className='z-50'>
      <a href="https://wa.me/+971542664712" target='_blank'>
        <img src="/assets/whatsapp-icon.png" alt="whatsapp-icon" className='fixed lg:bottom-20 bottom-[10%] right-2 left-50 w-14 h-14' />
      </a>
    </div>
  )
}
