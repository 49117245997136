export const products = [
    {
      id: "prod-01",
      title: "Mobile Accessories",
      description: "All types of mobile accessories available at FlexTech! From cases, chargers, headphones to screen protectors and more. Quality products, affordable prices, and exceptional customer service!",
      iconSrc: "/assets/img-7.png",
      imageURL: "https://i.imgur.com/ick0icL.jpg",
    },
    {
      id: "prod-02",
      title: "Wide Range of Phones",
      description: "We offer a wide range of both new keypad phones and the latest smartphones to suit your needs. Whether you're looking for a classic, easy-to-use keypad phone or a high-performance smartphone with cutting-edge features, we have options available at competitive prices.",
      iconSrc: "/assets/img-12.png",
      imageURL: "https://4.imgur.com/b1JKG6R.jpg",
    },
    {
      id: "prod-03",
      title: "Unlocking and OS Reinstallation Services",
      description: "We specialize in unlocking phones, fixing stuck devices, and reinstalling operating systems. Our expert technicians are equipped to handle a wide range of issues, ensuring your device runs smoothly and efficiently.",
      iconSrc: "/assets/img-9.png",
      imageURL: "https://4.imgur.com/b1JKG6R.jpg",
    },
    {
      id: "prod-04",
      title: "Computer Accessories",
      description: "we specialize in providing a wide range of premium computer accessories to enhance your tech experience, we offer everything you need to optimize your setup. Whether you're a gamer, a tech enthusiast, or simply upgrading your workspace, we've got you covered with the latest products and unbeatable customer service.",
      iconSrc: "/assets/img-28.png",
      imageURL: "https://4.imgur.com/b1JKG6R.jpg",
    },
    {
      id: "prod-05",
      title: "Expert IT Services for Your Tech Needs",
      description: "We offer professional Windows installation, data recovery, data transfer, and software installation services. Whether you need to set up a new system, recover lost data, or install essential software, we've got you covered. Trust us for reliable, fast, and expert solutions to keep your devices running smoothly!",
      iconSrc: "/assets/laptop.png",
      imageURL: "https://4.imgur.com/b1JKG6R.jpg",
    },
    {
      id: "prod-06",
      title: "Keep Your Screens Flawless with Hydrogel Technology",
      description: "Protect your devices with our premium Hydrogel Screen Protectors! Available in clear glossy, matte, and privacy options, these protectors offer superior scratch resistance, a smooth touch experience, and full coverage for Phones, IPads, Laptops, Watches & Cameras.",
      iconSrc: "/assets/img-30.png",
      imageURL: "https://4.imgur.com/b1JKG6R.jpg",
    },
]
  
