import React from 'react'

// components
import Slider from '../components/Slider/Slider'
import { ContactSection } from '../components/ContactSection/ContactSection'
import { AboutSection } from '../components/AboutSection/AboutSection'
import { ProductSection } from '../components/ProductSection/ProductSection'
import { Footer } from '../components/Footer/Footer'
import Navbar from '../components/Navbar/Navbar'


export default function App() {
  return (
    <div className='w-full h-full'>
      <Navbar />
      <Slider />
      <ProductSection />
      <AboutSection />
      <ContactSection />
      <Footer />
    </div>
  )
}
