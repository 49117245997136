export const sliderData = [
    {
      image: "/assets/img-16.jpg",
    },
    {
      image: "/assets/img-17.jpg",
    },
    {
      image: "/assets/img-18.jpg",
    },
    {
      image: "/assets/img-4.jpg",
    },
    {
      image: "/assets/img-5.jpg",
    },
    {
      image: "/assets/img-19.jpg",
    },
    {
      image: "/assets/img-20.jpg",
    },
    {
      image: "/assets/img-21.jpg",
    },
    {
      image: "/assets/img-22.jpg",
    },
    {
      image: "/assets/img-23.jpg",
    },
    {
      image: "/assets/img-24.jpg",
    },
    {
      image: "/assets/img-25.jpg",
    },
    {
      image: "/assets/img-26.jpg",
    },
    {
      image: "/assets/img-27.jpg",
    },
    {
      image: "/assets/img-2.jpg",
    },
    {
      image: "/assets/img-3.jpg",
    },
  ];