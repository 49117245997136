import React from "react"

// Components
import { AboutSection } from "../../components/AboutSection/AboutSection"
import { Footer } from "../../components/Footer/Footer"
import Navbar from "../../components/Navbar/Navbar"

export const AboutUs = () => {
  return (
    <div className="w-full">
      <Navbar />
      <AboutSection />
      <Footer />
    </div>
  )
}
