import React from "react"

// icons
import { RiInstagramFill } from "react-icons/ri";
import { FaFacebook } from "react-icons/fa";
import { IoLogoLinkedin } from "react-icons/io5";
import { FaTiktok } from "react-icons/fa";



export const Footer = () => {
  return (
    <footer
      id="footer"
      className="footer container px-4 py-2 mx-auto max-w-[1800px] flex flex-col items-center justify-center mt-[80px] h-[130px] bg-[#eee]"
    >
      <div class="footer container h-[80px] flex flex-col gap-4 justify-center items-center max-w-[1800px] mx-auto">
        <span className="font-medium text-base text-center md:text-left">Copyright @ 2024 FlexTech Mobile Phones & Accessories Trading CO. L.L.C</span>
        <div className="flex justify-between items-center gap-4">
          <a href="https://www.tiktok.com/@flextechmobiles" target="_blank">
          <FaTiktok className="text-deep-blue w-6 h-6 cursor-pointer"  />
          </a>
          <a href="https://www.facebook.com/profile.php?id=61563419494615" target="_blank">
          <FaFacebook className="text-deep-blue w-6 h-6 cursor-pointer" />
          </a>
          <a href="https://www.instagram.com/flextechmobiles" target="_blank">
          <RiInstagramFill className="text-deep-blue w-6 h-6 cursor-pointer" />
          </a>
          <IoLogoLinkedin className="text-deep-blue w-6 h-6 cursor-pointer" />
        </div>
      </div>
    </footer>
  )
}
