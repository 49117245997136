import React from "react"

export const ProductCard = ({ title, description, iconSrc }) => {
  return (
    <div
      className="min-w-sm max-w-xs md:max-w-[350px] min-h-[590px] flex flex-col p-[50px] rounded-lg bg-grey shadow-xlg  border-b-4 border-blue-300
      transition-transform duration-300 hover:translate-y-[-4px]"
    >
      <div className="flex justify-center items-center h-[170px] max-h-[170px] w-[190px] max-w-[190px] bg-cover self-center">
        <img src={iconSrc} alt="icon"  className="h-full w-full rounded-lg" />
      </div>
      <h2 className="md:text-2xl text-lg font-medium text-black mt-4 text-center flex justify-center items-center overflow-hidden">
        {title}
      </h2>
      <p className="text-base text-black leading-snug text-center mt-2">
        {description}
      </p>
    </div>

  )
}
